/* eslint-disable import/prefer-default-export, no-param-reassign, @typescript-eslint/no-unused-vars */

import * as yup from 'yup'
import { ArraySchema, object, ObjectSchema } from 'yup'
import { IntakeFieldType } from '../../../../types/Intake'
import { getSingleInputFieldValidationSchema, getType } from './formValidationSchema'
import { exact, max, min, sumEquals } from './tests'

yup.addMethod(yup.number, 'customMin', min)
yup.addMethod(yup.number, 'customMax', max)
yup.addMethod(yup.number, 'exact', exact)
yup.addMethod(yup.string, 'exact', exact)
yup.addMethod(yup.array, 'exact', exact)

yup.addMethod(yup.array, 'total', sumEquals)

/**
 * @param {IntakeFieldType} question
 * @returns {ArraySchema}
 */
const generateYupValidator = (question) => {
  const yupType = getType(question)

  if (!yup[yupType] || typeof yup[yupType] !== 'function') {
    throw new Error(`Received invalid validation type '${yupType}' for question of type '${question.type}'`)
  }

  const applyValidation = (validator, schema) => {
    Object.entries(schema).forEach(([property, isEnabledOrValidationArgs]) => {
      if (!validator[property] || typeof validator[property] !== 'function') {
        const errorMessage = `Invalid test '${property}' (question type: '${question.type}', validator: '${validator.type}')`
        console.warn(errorMessage)

        return // throw new Error(errorMessage)
      }

      if (
        isEnabledOrValidationArgs === null ||
        isEnabledOrValidationArgs === undefined ||
        isEnabledOrValidationArgs === false
      )
        return

      if (isEnabledOrValidationArgs === true) validator = validator[property]()
      else if (Array.isArray(isEnabledOrValidationArgs)) validator = validator[property](...isEnabledOrValidationArgs)
      else validator = validator[property](isEnabledOrValidationArgs)
    })

    return validator
  }

  const fieldValidator = applyValidation(yup[yupType](), getSingleInputFieldValidationSchema(question))
  // const questionValidator = applyValidation(yup.array().of(fieldValidator), getWholeQuestionValidationSchema(question))

  return fieldValidator
}

/**
 * @param {IntakeFieldType[]} questions
 * @returns {ObjectSchema}
 */
export const generateYupSchema = (questions) =>
  object(questions.reduce((index, question) => ({ ...index, [question.uuid]: generateYupValidator(question) }), {}))
