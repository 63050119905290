import { ForwardedRef, forwardRef, useLayoutEffect } from 'react'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'
import useIntakeFormStore from '../../IntakeFormResponse/stores/useIntakeFormStore'
import SectionRenderer from '../../IntakeFormResponse/components/IntakeFormSections/SectionRenderer'

export const IntakeFlowOverviewPageDetails = forwardRef((props, detailRef: ForwardedRef<HTMLDivElement>) => {
  const { intake } = useIntakeFlow()

  const setIntake = useIntakeFormStore((state) => state.setIntake)
  const resetFormStore = useIntakeFormStore((state) => state.reset)

  useLayoutEffect(() => {
    if (!intake) return

    resetFormStore()
    setIntake(intake)
  }, [intake?.uuid])

  if (!intake) return null

  return (
    <div ref={detailRef} className="flex flex-col gap-11 text-black-light max-w-7xl mx-auto">
      {intake.sections.map((section) => (
        <SectionRenderer section={section} key={`section-${section.id}`} useSectionWrapper />
      ))}
    </div>
  )
})
IntakeFlowOverviewPageDetails.displayName = 'IntakeFlowOverviewPageDetails'
export default IntakeFlowOverviewPageDetails
